body {
  background-color: white;
  height: 100vh;
}

.Home {
  background-color: white;
  height: 100vh;
  width: 100%;
}

.homeProfile {
  margin-left: 32px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 90%;
  width: 30%;
  text-align: center;
  margin-top: 16px;
}

.homeImg {
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

.home_container1 {
  width: 100%;
  height: 50%;
  display: flex;
}

.home_container2 {
  width: 70%;
  height: 100%;
  position: relative;
  margin-right: 20px;
}

.home_container3 {
  display: flex;
  height: 60%;
}
.addPostLayout {
  width: 100%;
  height: 100%;
  margin-left: 32px;
  margin-top: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  position: relative;
}

.followersLayout {
  width: 50%;
  height: 90%;
  text-align: center;
  padding: 5px;
  background-color: #28b498;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.btnContainer {
  display: flex;
  justify-content: space-around;
}

.logoutBtn {
  background-color: #28b498;
  color: white;
  border: none;
  font-weight: bold;
  margin-top: 16px;
  border-radius: 10px;
  width: 40%;
}

.profileBtn {
  background-color: #28b498;
  color: white;
  border: none;
  border-radius: 10px;
  height: 24px;
  font-weight: bold;
  width: 40%;
  margin-top: 16px;
}

.updateStatusBtn {
  height: 32px;
  background-color: #28b498;
  color: white;
  margin-left: 16px;
  border: none;
  border-radius: 10px;
  width: 20%;
}

.imagePostContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.addPost {
  margin-top: 8px;
  width: 40%;
  height: 50%;
}

.addPostField {
  height: 100%;
  border-radius: 10px;
  padding: 10px;
}

.addImage {
  margin-top: 8px;
  text-align: center;
  margin-right: 8px;
  border: 1px solid black;
  border-radius: 10px;
  height: 50%;
  width: 40%;
}

.addimagefield {
  text-align: center;
}

.postBtnLayout {
  margin-top: 32px;
  width: 100%;
  position: absolute;
  bottom: 8px;
}

.postBtnsContainer {
  display: flex;
  justify-content: center;
}

.cancelPostBtn {
  margin-right: 8px;
  background-color: #28b498;
  width: 40%;
  border: none;
  height: 32px;
  color: white;
  border-radius: 10px;
}
.postBtn {
  width: 40%;
  margin-left: 8px;
  border: none;
  background-color: #28b498;
  height: 32px;
  color: white;
  border-radius: 10px;
}

.changeStatus {
  background-color: #eef5f3;
  height: 32px;
  border: none;
  border-radius: 10px;
  width: 60%;
  padding-left: 10px;
}

.searchField {
  width: 90%;
  left: 20px;
  right: 20px;
  bottom: 32px;
  display: flex;
  justify-content: center;
  position: absolute;
  text-align: center;
}

.searchInputField {
  width: 100%;
  height: 32px;
  background-color: #eef5f3;
  border: none;
  border-radius: 10px;
  padding-left: 10px;
}

.AddFriendLayout {
  width: 30%;
  height: fit-content;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin-top: 16px;
  border-radius: 10px;
  text-align: center;
}

.topSearchFriends {
  padding: 16px;
}

.friend1 {
  height: 25%;
  text-align: center;
  margin-top: 64px;
}

.friend2 {
  margin-top: 32px;
  height: 25%;
  text-align: center;
}

.friend3 {
  margin-top: 32px;
  height: 25%;
  text-align: center;
}

.searchImage1,
.searchImage2,
.searchImage3 {
  width: 50%;
  height: 80%;
  border-radius: 40%;
}

.friendName {
  font-weight: bold;
}

.searchForFriends {
  height: 10%;
  text-align: center;
  margin-top: 64px;
}

.followFriendsEt {
  margin-right: 8px;
  background-color: #eef5f3;
  border: none;
  height: 24px;
  width: 50%;
  border-radius: 10px;
  padding-left: 10px;
}

.followbtn {
  margin-left: 8px;
  background-color: #28b498;
  border: none;
  border-radius: 10px;
  height: 24px;
  color: white;
  font-weight: bold;
  width: 30%;
}

.layer2 {
  height: 100%;
  width: 100%;
  display: flex;
  margin-top: 32px;
  padding: 32px;
}

.postLayout {
  height: 95%;
  width: 70%;
  margin-left: 32px;
  margin-top: 16px;
  margin-right: 64px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  /* pointer-events: none; */
  justify-content: space-between;
}

.Post {
  width: 40%;
  height: 45%;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
  background-color: white;
  border-radius: 15px;
  position: relative;
  text-align: center;
}
/* .Post:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
} */
.Post h2 {
  font-size: 20px;
  color: #28b498;
}
.Post a {
  text-decoration: none;
}

.content {
  position: absolute;
  bottom: 0px;
  width: 95%;
  border-radius: 15px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.4);
  color: #f1f1f1;
  padding: 2px;
  margin-left: 2%;
}

.bgImg {
  height: 100%;
  width: 100%;
  border-radius: 15px;
}

.btnLayout {
  display: flex;
  justify-content: center;
}

.postCmntBtn {
  margin-right: 16px;
  background-color: #28b498;
  border: none;
  height: 24px;
  width: 30%;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  padding: 5px;
}

.postEditBtn {
  margin-left: 16px;
  background-color: #28b498;
  border: none;
  height: 24px;
  width: 30%;
  font-weight: bold;
  color: white;
  border-radius: 10px;
}

.ufBtns {
  background-color: #28b498;
  color: white;
  border: none;
  height: 24px;
  border-radius: 10px;
  width: 40%;
  margin-top: 8px;
  font-weight: bold;
}

.following_image {
  height: 20%;
  width: 20%;
  border-radius: 50%;
}

.following_container {
  display: flex;
}

.followersLayout {
  background-color: white;
  width: 40%;
  height: 90%;
  margin-left: 32px;
  margin-top: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
}

.newPostLayout {
  width: 100%;
  height: 100%;
  text-align: center;
}

.addImageBtn {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 10px;
}

.addPostField {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.addFriendBtn {
  height: 32px;
  background-color: #28b498;
  border: none;
  border-radius: 10px;
  color: white;
  width: 30%;
  font-weight: bold;
}

.followText {
  color: #28b498;
  font-weight: bold;
}

.statusUsername {
  font-weight: bold;
}

.newStatusContainer {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

dialog {
  border: none;
  box-shadow: #00000029 2px 2px 5px 2px;
  border-radius: 10px;
  padding: 30px;
  background-color: white;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: bold;
}

.all_comments {
  display: none;
}

.home2Layout {
  display: flex;
  flex-direction: column;
}

.paginationField {
  display: flex;
  align-self: center;
}
