.profile_page {
  height: 100%;
  width: 100%;
}
.profileImgLayout {
  background-color: white;
  width: 40%;
  height: 100%;
  text-align: center;
  margin-top: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding-bottom: 10px;
  padding: 20px;
}

.profileUname {
  font-size: 24px;
  margin-top: 10px;
}
.profileImg {
  max-height: 200px;
  width: 200px;
  margin: auto;
  border-radius: 50%;
}

.uploadImgBtn {
  background-color: #28b498;
  color: white;
  border-radius: 15px;
  border: none;
  font-weight: bold;
  margin: auto;
  height: 32px;
  width: 200px;
  margin-top: 10px;
}

.updateImgBtn {
  background-color: #28b498;
  color: white;
  border-radius: 15px;
  border: none;
  font-weight: bold;
  margin: auto;
  height: 32px;
  width: 200px;
  margin-top: 10px;
}

.uInfoLayout {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  height: 70%;
  width: 100%;
  padding: 20px;
}

.userDetails {
  background-color: white;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-right: 64px;
  padding: 20px;
}

.updateInfo {
  width: 40%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  text-align: center;
}

.heading {
  text-align: center;
  color: #28b498;
}

.updateInfo input {
  background-color: #eef5f3;
  border: none;
  padding-left: 10px;
  height: 32px;
  border-radius: 10px;
  width: 70%;
}

.updateBtn {
  background-color: #28b498;
  border: none;
  width: 70%;
  border-radius: 10px;
  height: 32px;
  color: white;
  font-weight: bold;
}

.homeLayoutText {
  margin-top: 8px;
}

.home_text {
  color: #28b498;
  font-weight: bold;
  background: none;
  border: none;
  margin-top: 8px;
  font-size: 18px;
}

.following_image {
  height: 20%;
  width: 20%;
  border-radius: 50%;
}

.following_container {
  display: flex;
}

.profile_container1 {
  display: flex;
  height: 30%;
  width: 100%;
  justify-content: center;
}
